<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col md="9">
        <b-overlay
          variant="white"
          :show="isLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <b-card>
            <div class="d-flex justify-content-center">
              <h3 class="font-weight-bold my-1">
                RIASEC TEST
              </h3>
            </div>
            <b-alert
              v-if="testCompleted"
              variant="info"
              show
              class="text-center"
            >
              You have already completed this test.
            </b-alert>
            <b-progress
              :max="totalQuestions"
              height="20px"
              class="mb-4 mx-2"
            >
              <b-progress-bar
                :value="currentProgress"
                variant="success"
              >
               
              </b-progress-bar>
            </b-progress>

            <div
              v-for="(question, index) in currentBatch"
              :key="question.id"
              class="mb-3 ml-2"
            >
              <b-form-group :label="`${startIndex + index + 1}. ${question.question_text}`">
                <b-form-radio-group
                  v-model="answers[question.id]"
                  :options="answerOptions"
                  :name="`question-${question.id}`"
                  stacked
                />
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between mx-2">
              <b-button
                variant="warning"
                :disabled="currentBatchIndex === 0 || testCompleted"
                @click="previousBatch"
              >
                Back
              </b-button>
              <b-button
                v-if="currentBatchIndex < maxBatchIndex"
                variant="primary"
                :disabled="!canProceedToNext || testCompleted"
                @click="nextBatch"
              >
                Next
              </b-button>
              <b-button
                v-else
                variant="success"
                :disabled="!canProceedToNext || testCompleted"
                @click="finishTest"
              >
                Finish
              </b-button>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      questions: [],
      answers: {},
      currentBatchIndex: 0,
      batchSize: 6,
      testCompleted: false,
      answerOptions: [
        { text: 'Agree', value: 1 },
        { text: 'Disagree', value: 0 },
      ],
    }
  },
  computed: {
    currentBatch() {
      const start = this.currentBatchIndex * this.batchSize
      const end = start + this.batchSize
      return this.questions.slice(start, end)
    },
    totalQuestions() {
      return this.questions.length
    },
    currentProgress() {
      return this.currentBatchIndex * this.batchSize + this.currentBatch.length
    },
    maxBatchIndex() {
      return Math.ceil(this.totalQuestions / this.batchSize) - 1
    },
    startIndex() {
      return this.currentBatchIndex * this.batchSize
    },
    canProceedToNext() {
      // Check if all questions in the current batch are answered
      return this.currentBatch.every(question => this.answers[question.id] !== undefined)
    },
  },
  async created() {
    await this.fetchQuestions()
    const batchFromParams = parseInt(this.$route.query.batch, 10) || 1
    this.currentBatchIndex = Math.max(0, batchFromParams - 1)
  },
  methods: {
    async fetchQuestions() {
      this.isLoading = true
      const { riasec_id } = this.$route.params
      try {
        const response = await this.$http.get(`/api/riasec/questions/${riasec_id}`)
        this.isLoading = false
        if (response.data.testCompleted) {
          this.testCompleted = true
          return
        }
        this.questions = response.data.questions
      } catch (error) {
        this.isLoading = false
        console.error('Failed to fetch questions:', error)
      }
    },
    async nextBatch() {
      if (this.currentBatchIndex < this.maxBatchIndex) {
        this.isLoading = true
        await this.saveAnswers()
        this.currentBatchIndex += 1
        this.updateURLParams()
      }
    },
    async previousBatch() {
      if (this.currentBatchIndex > 0) {
        this.currentBatchIndex -= 1
        this.updateURLParams()
      }
    },
    updateURLParams() {
      this.$router.push({ query: { batch: this.currentBatchIndex + 1 } })
    },
    async saveAnswers() {
      // Save the current batch of answers to the backend
      const { riasec_id } = this.$route.params
      try {
        await this.$http.post(`/api/riasec/answers/${riasec_id}`,
          { record: { answers: this.answers, batch: this.currentBatchIndex + 1 } })
        this.isLoading = false
      } catch (error) {
        console.error('Failed to save answers:', error)
      }
    },
    async finishTest() {
      this.isLoading = true
      await this.saveAnswers()
      // Redirect to a congratulations page
      this.$router.push({
        name: 'riasec-congratulation',
        params: { riasec_id: this.$route.params.riasec_id },
      })
    },
  },
}
</script>

<style scoped>
/* Add custom styles here if needed */
</style>
